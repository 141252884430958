import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"

const Location = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Banner title="Kontakt" />
  </Layout>
)

export default Location
